<template>
    <CRow>
        <CCol sm="12">
            <ListForm :headerTitle="$t('common.historyDiscountsAndCommissions')" :onSearch="onSearch">
                <template v-slot:searchBox>
                    <CForm>
                        <CRow>
                            <CCol md="5">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">{{$t('common.startDate')}} </label>
                                    <div class="col-sm-9">
                                        <date-picker v-model="searchInfo.startDate" format="DD/MM/YYYY"></date-picker>
                                    </div>
                                </div>
                            </CCol>
                            <CCol md="7">
                                <CInput :label="$t('pages.orders.orderNumber')" :placeholder="$t('pages.orders.enterorderNumber')" v-model="searchInfo.orderNumber" horizontal></CInput>
                            </CCol>
                        </CRow>

                        <CRow>
                            <CCol md="5">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">{{$t('common.endDate')}} </label>
                                    <div class="col-sm-9">
                                        <date-picker v-model="searchInfo.endDate" format="DD/MM/YYYY"></date-picker>
                                    </div>
                                </div>
                            </CCol>
                            <CCol md="7">
                                <CInput :label="$t('common.name')" :placeholder="$t('pages.account.enterName')" v-model="searchInfo.userName" horizontal></CInput>
                            </CCol>
                        </CRow>                       
                    </CForm>

                </template>
                <template v-slot:list>
                    <div class="d-flex" v-if="totalItems != null && totalItems > 0">                       
                        <span><b><label>Tổng số tiền:</label></b> </span><span style="padding-left:5px;color:crimson;font-weight:bold">{{ $func.formatCurrency(totalAmount) }}</span>
                    </div>
                    <CDataTable :items="commissionsList"
                                :fields="fields"
                                :noItemsView="{ noItems: $t('common.nodatafound') }"
                                hover
                                striped
                                border
                                small
                                fixed
                                :loading="isLoading"
                                pagination
                                :items-per-page="itemsPerPage"
                                @pagination-change="onItemsPerPageChange"
                                :items-per-page-select="{
                                label : $t('common.recordsperpage'),
                                values : [10,20,50,100] }">                        
                        <template #footer v-if="commissionsList != null && commissionsList.length > 0">
                            <tfoot>
                                <tr>
                                    <td :colspan="7">
                                        <span>{{ $t('common.totalRecords') }}: </span><span style="padding-left:5px;color:crimson;font-weight:bold">{{ totalItems }}</span>
                                    </td>
                                </tr>
                            </tfoot>
                        </template>
                        <template #amount_text="{item}">
                            <td class="py-2">
                                <label v-text="$func.formatNumber(item.amount)" />
                            </td>
                        </template>
                        <template #createdDate_text="{item}">
                            <td class="py-2">
                                <label v-text="formatDate(item.createdDate)" />
                            </td>
                        </template>
                        <template #dealerOrders_Detail="{item}">
                            <td class="py-2">
                                <CButton color="primary" variant="outline" square size="sm" @click="onViewOrderDetail(item)">
                                    {{ $t('common.detail') }}
                                </CButton>
                            </td>
                        </template>

                        <template #over-table>
                            <CPagination :activePage.sync="page"
                                         @update:activePage="onPageChange"
                                         :pages="totalPages"
                                         v-show="totalPages > 1"
                                         size="sm"
                                         align="center" />
                            Trang hiện tại: {{pageIndex + 1}}
                            Tổng số trang: {{totalPages}}
                        </template>

                        <template #under-table>
                            <CPagination :activePage.sync="page"
                                         @update:activePage="onPageChange"
                                         :pages="totalPages"
                                         v-show="totalPages > 1"
                                         size="sm"
                                         align="center" />
                            Trang hiện tại: {{pageIndex + 1}}
                            Tổng số trang: {{totalPages}}
                        </template>
                    </CDataTable>
                </template>
            </ListForm>          
        </CCol>
    </CRow>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import i18n from '@/plugins/i18n'   
    import ListForm from '@/components/ListForm.vue'   
    
    export default {
        name: 'Discounts-Commissions',
        data() {
            return {                                             
                itemsPerPage: 10,
                warningModal: false,
                fields: [
                    { key: 'userName', label: i18n.t('common.name') },
                    { key: 'roleName', label: i18n.t('common.roleName') },
                    { key: 'orderId', label: i18n.t('pages.orders.orderNumber') },
                    { key: 'commissionRate', label: i18n.t('common.commissionRate') },
                    { key: 'amount_text', label: i18n.t('common.amount') },
                    { key: 'createdDate_text', label: i18n.t('common.createdDate') },
                    { key: 'dealerOrders_Detail', label: '' }
                ],
                startDate: null,                
            };
        },
        components: {
            ListForm
        },
        computed: {            
            ...mapState('commissions', ['searchInfo', 'isLoading', 'commissionsList','totalAmount', 'totalItems', 'totalPages', 'pageIndex']),    
            page: {
                get() {
                    return this.pageIndex + 1;
                },
                set(newVal) {

                }
            },
        },
        methods: {
            ...mapActions('commissions', ['searchCommissions']),

            formatDate(date) {
                if (date === undefined)
                    return '';
               
                return this.$moment(new Date(date), 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');   
            },            
            onSearch() {                
                this.searchCommissions({ pageIndex: 0, pageSize: this.itemsPerPage });
            },                         
            onItemsPerPageChange(item) {
                this.itemsPerPage = item;
                localStorage.setItem('commissionsItemsPerPage', item)

                if (this.page > 0) {
                    this.searchCommissions({ pageIndex: this.page - 1, pageSize: this.itemsPerPage });
                }
            },
            onPageChange(newPageNumber) {                
                this.searchCommissions({ pageIndex: newPageNumber - 1, pageSize: this.itemsPerPage });
            },
            async onViewOrderDetail(item) {
                this.$router.push('/warehouses/view-order-detail/' + item.orderId);
            },            
        },
       
        created() {                  
            this.onSearch();
            
            let _iTemPerPage = parseInt(localStorage.getItem('commissionsItemsPerPage'));
            if (isNaN(_iTemPerPage))
                _iTemPerPage = 10;
            this.itemsPerPage = _iTemPerPage;
        }
	}
</script>

